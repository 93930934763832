import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from './@Services/services/language/language.service';
import { LanguageDto } from './@Services/models/language/lang-model';
import { BaseResponseDto } from './@Services/services/Base/BaseResponseDto';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  LanguageObj: LanguageDto;
  constructor(public translate: TranslateService, private languageServices: LanguageService) {
    this.languageServices.getDefualtLanguage().toPromise().then((result): Observable<BaseResponseDto> => {
      if (result.statusRequest) {
        this.LanguageObj = result.innerData as LanguageDto;
        localStorage.setItem("langKey", this.LanguageObj.name);
        localStorage.setItem("langId", this.LanguageObj.id.toString());
      }
      else{
        localStorage.setItem("langKey", "ar");
        localStorage.setItem("langId", "1");
      }
      return;
    });

    // this.translate.addLangs(['ar', 'en'])
    // this.translate.setDefaultLang('ar');
    // const browserLang = translate.getBrowserLang();
    // this.translate.use(browserLang.match(/ar|en/) ? browserLang : 'ar');

  }
}
