import { Component, OnInit, Input } from '@angular/core';
import { ProductDTO,ProductByNumberDTO } from '../../../@Services/models/product/products-models';
import { Observable } from 'rxjs';
import { BaseResponseDto } from '../../../@Services/services/Base/BaseResponseDto';
import { ProductService } from '../../../@Services/services/product/product.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {

  @Input() product:ProductDTO
  constructor(private prdouctServices: ProductService,private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  async ViewProductDetails(content: any, productNumber: string) {
    var ProductByNumberDTO: ProductByNumberDTO = { number: productNumber };
    await this.prdouctServices.getByNumber(ProductByNumberDTO).then((result): Observable<BaseResponseDto> => {
      this.product = result.innerData as ProductDTO
      return;
    });
    this.modalService.open(content, { size: 'lg' });
  }

}
