import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginDto } from '../../../@Services/models/auth/auth-model';
import { BaseResponseDto } from '../../../@Services/services/Base/BaseResponseDto';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from '../../../@Services/services/auth/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

  constructor(private fb: FormBuilder, private authService: AuthService) { }

  frm: FormGroup;
  loginObj: LoginDto;
  jwtHelper = new JwtHelperService();
  decodedToken: any;

  ngOnInit(): void {
    this.frm = this.fb.group({
      email: ['hima@s.com', [Validators.required, Validators.email, Validators.minLength(3), Validators.maxLength(50)]],
      password: ['Him@ss12', [Validators.required, Validators.minLength(8), Validators.maxLength(25)]],
    });
  }

  get EmailProperty() {
    return this.frm.get('email');
  }

  get PasswordProperty() {
    return this.frm.get('password');
  }

  async onSubmit() {
    if (this.frm.valid) {
      this.loginObj = {
        email: 'hima@s.com',//this.frm.controls.email.value,
        password: 'Him@ss12',//this.frm.controls.password.value,
        phoneNumber: '',
      }

      await this.authService.login(this.loginObj);
      // await this.authService.Login(this.loginObj).then((result): BaseResponseDto => {
      //   let token: string = result.innerData as string
      //   localStorage.setItem('token', token);
      //   this.decodedToken = this.jwtHelper.decodeToken(token);
      //   console.log(this.decodedToken);
      //   return;
      // });
    }
  }

}
