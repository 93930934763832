import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SpinnerService } from '../spinner/spinner.service';
//import { runInNewContext } from 'vm';
import { delay, finalize } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor
{
    constructor(private spinnerService:SpinnerService){}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.spinnerService.Busy();
        return next.handle(req).pipe(
            delay(1000),
            finalize(() => {
                this.spinnerService.Idle();
            })
        );
    }
    
}