import { Component, OnInit } from '@angular/core';
import { CategoryService } from '../../../@Services/services/category/category.service';
import { Observable } from 'rxjs';
import { BaseResponseDto } from '../../../@Services/services/Base/BaseResponseDto';
import { CategoriesSubCategoriesDTO } from '../../../@Services/models/category/category-models';
import { ProductService } from 'app/@Services/services/product/product.service';

@Component({
  selector: 'app-main-slider',
  templateUrl: './main-slider.component.html',
  styleUrls: ['./main-slider.component.css',
    '../../../../assets/css/site/Mega-Menu.css']
})

export class MainSliderComponent implements OnInit {

  constructor(private productService:ProductService) { }
  categories: CategoriesSubCategoriesDTO[] = [];
  path:string = "../../../../assets/img/";
  ngOnInit(): void {
    this.FillMenus();
  }

  FillMenus() {
    this.productService.FillMenu().subscribe((result): Observable<BaseResponseDto> => {
      this.categories = result.innerData as CategoriesSubCategoriesDTO[]
      console.log(this.categories);
      return;  
    })
  }

  // async FillMenus() {
  //   await this.categoryService.fillMenu().then((result): Observable<BaseResponseDto> => {
  //     this.categories = result.innerData as CategoriesSubCategoriesDTO[]
  //     console.log(this.categories);
  //     return;
  //   });
  // }

}
