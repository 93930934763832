import { Injectable } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";

@Injectable({
  providedIn: "root",
})
export class SpinnerService {
  busyRequestCount = 0;

  constructor(private spinnerService: NgxSpinnerService) {}

  Busy() {
    this.busyRequestCount++;
    this.spinnerService.show(undefined, {
      type: "pacman",
      bdColor: "rgba(255,255,255,0.7)",
      color: "#0a72ee",
      size: 'default',
      fullScreen : true
    });
  }

  Idle() {
    this.busyRequestCount--;
    if (this.busyRequestCount <= 0) {
      this.busyRequestCount = 0;
      this.spinnerService.hide();
    }
  }
}
