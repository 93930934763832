import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SiteRoutingModule } from './site-routing.module';
import { HomeComponent } from './pages/home/home.component';
import { ViewProductsComponent } from './pages/view-products/view-products.component';
import { HeaderComponent } from './components/header/header.component';

import { SubjectsComponent } from './components/subjects/subjects.component';
import { SliderProductsComponent } from './components/slider-products/slider-products.component';
import { MainSliderComponent } from './components/main-slider/main-slider.component';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ProductService } from '../@Services/services/product/product.service';
import { ViewProductDetailsComponent } from './pages/view-product-details/view-product-details.component';

import { NgbModule, NgbCarouselModule, NgbSlide } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from './components/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RegisterComponent } from './components/register/register.component';
import { ViewSubCategoryComponent } from './components/view-subCategory/view-sub-category.component';
import { DisplayProductsComponent } from './components/display-products/display-products.component';
import { DisplayProductComponent } from './components/display-product/display-product.component';
import { ProductComponent } from './components/product/product.component';
import { Carousel } from 'bootstrap';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    HomeComponent,
    ViewProductsComponent,
    HeaderComponent,
    SubjectsComponent,
    SliderProductsComponent,
    MainSliderComponent,
    ViewProductDetailsComponent,
    LoginComponent,
    RegisterComponent,
    ViewSubCategoryComponent,
    DisplayProductsComponent,
    DisplayProductComponent,
    ProductComponent,
  ],

  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SiteRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    ProductService,
  ],
  bootstrap: [HomeComponent], //SiteComponent
})
export class SiteModule { }
