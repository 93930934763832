import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
//
export class BasicAuthInterceptorService implements HttpInterceptor {
  constructor(private authService: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var isLogged = this.authService.LoggedIn();
    const languageKey = localStorage.getItem('langKey');
    const languageValue = localStorage.getItem('langId');
    if (isLogged) {
      const userToken = localStorage.getItem("token");
      request = request.clone({
        setHeaders: {
          ["Authorization"]: `Bearer ${userToken}`,
          ["Accept-Language"]: `${languageKey}`,
          ["LanguageId"]: `${languageValue}`
        }
      });
    }
    else {
      request = request.clone({
        setHeaders: {
          ["Accept-Language"]: `${languageKey}`,
          ["LanguageId"]: `${languageValue}`
        }
      });
    }
    return next.handle(request);
  }
}
