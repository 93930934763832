import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ProductService } from '../../../@Services/services/product/product.service';
import { ProductsPadgingResponse, ProductsPagingReqestDTO, ProductDTO, ProductByNumberDTO, ProductsPagingReqestByFKDTO, DDL_CategoryAndSubCategoryDTO } from '../../../@Services/models/product/products-models';
import { Observable } from 'rxjs';
import { BaseResponseDto } from '../../../@Services/services/Base/BaseResponseDto';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PaginationDTO } from '../../../@Services/models/generic-model/Pagination';
import { SubCategoryService } from '../../../@Services/services/sub-category/sub-category.service';
import { GetSubcategoriesByCategoryId, DDLSubCategoryDTO, DDL_SubCategoryDTO } from '../../../@Services/models/subCatgory/subCategory-models';
import { CategoryService } from '../../../@Services/services/category/category.service';
import { DDLCategoryDTO } from '../../../@Services/models/category/category-models';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-view-products',
  templateUrl: './view-products.component.html',
  styleUrls: ['./view-products.component.css']
})
export class ViewProductsComponent implements OnInit {

  constructor(private fb: FormBuilder, private prdouctServices: ProductService, private modalService: NgbModal) { }
  @ViewChild('search', { static: false }) searchTerm: ElementRef;
  frm: FormGroup;
  request: ProductsPagingReqestByFKDTO = { skip: 0, take: 8, subcategoryId: 0, name: '' };
  productList: ProductsPadgingResponse[] = [];
  paginationDTO: PaginationDTO;
  subCategoryIdSelected = 0;
  categoryAndSubcategoryList: DDL_CategoryAndSubCategoryDTO;
  categoryList: DDLCategoryDTO[] = [];
  subCategoryList: DDL_SubCategoryDTO[] = [];

  async ngOnInit(): Promise<void> {
    this.frm = this.fb.group({
      categoryId: ['', [Validators.required]],
    });
    //
    this.LoadProducts();
    this.FillCategories();
  }

  async LoadProducts() {
    await this.prdouctServices.getProductsPadging(this.request as ProductsPagingReqestByFKDTO)
      .toPromise().then((result): Observable<BaseResponseDto> => {
        this.paginationDTO = result.innerData as PaginationDTO;
        this.productList = this.paginationDTO.list;
        console.log("Products => ", result);
        return;
      });
  }

  ViewMoreProducts() {
    this.request.take += 8;
    this.request.subcategoryId = +this.subCategoryIdSelected
    this.LoadProducts();
  }

  CategorySelected(value: string) {
    if (value !== "0") {
      this.subCategoryList = this.categoryAndSubcategoryList.subCategory.filter(e => e.categoryId == +value);
    }
    else {
      this.subCategoryIdSelected = 0;
      this.subCategoryList = [];
      this.ViewMoreProducts();
    }
    // let list = this.categoryAndSubcategoryList.subCategory.filter(e => e.categoryId == +value);
    // this.subCategoryList = [{ id: 0, name: 'عرض الكل', nameEn: 'View All', categoryId: 0 }, ...list];
  }

  OnSubCategorySeleted(subcategoryId: string) {
    this.request = { skip: 0, take: 8, subcategoryId: +subcategoryId, name: '' };
    //
    this.subCategoryIdSelected = +subcategoryId;
    this.LoadProducts();
  }

  FillCategories() {
    this.prdouctServices.GetCatSubCatUsed().subscribe((result: BaseResponseDto) => {
      debugger;
      this.categoryAndSubcategoryList = result.innerData;
      this.categoryList = [{ id: 0, name: 'عرض الكل', nameEn: 'View All' }, ...this.categoryAndSubcategoryList.category];
      //this.categoryList = this.categoryAndSubcategoryList.category;
    });
  }

  onSearch() {
    this.request.take = 8;
    this.request.name = this.searchTerm.nativeElement.value;
    this.ViewMoreProducts();
  }

  onReset() {
    this.searchTerm.nativeElement.value = '';
    this.request.take = 8;
    this.request.name = '';
    this.ViewMoreProducts();
  }

  get categoryProperty() {
    return this.frm.get('categoryId');
  }


  // async ViewProductDetails(content: any, productNumber: string) {
  //   var ProductByNumberDTO: ProductByNumberDTO = { number: productNumber };
  //   await this.prdouctServices.getByNumber(ProductByNumberDTO).then((result): Observable<BaseResponseDto> => {
  //     this.ProductDto = result.innerData as ProductDTO
  //     console.log(this.ProductDto);
  //     return;
  //   });
  //   this.modalService.open(content, { size: 'lg' });
  // }

}
