import { Component, OnInit } from '@angular/core';
import { ProductService } from '../../../@Services/services/product/product.service';
import { ActivatedRoute } from '@angular/router';
import { ProductByNumberDTO, ProductDTO } from '../../../@Services/models/product/products-models';
import { BaseResponseDto } from '../../../@Services/services/Base/BaseResponseDto';

@Component({
  selector: 'app-display-product',
  templateUrl: './display-product.component.html',
  styleUrls: ['./display-product.component.css']
})
export class DisplayProductComponent implements OnInit {

  constructor(private productService: ProductService, private activatedRoute: ActivatedRoute) { }
  obj: ProductByNumberDTO;
  ProductDto: ProductDTO;

  ngOnInit(): void {
    this.loadProduct(this.activatedRoute.snapshot.paramMap.get('id'));
  }

  loadProduct(productNumber: string) {
    this.obj = { number: productNumber }
    this.productService.getByNumber(this.obj).then((result): BaseResponseDto => {
      this.ProductDto = result.innerData;
      return;
    })
  }

}
