import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { GenericService } from '../Base/GenericService';
import { BaseResponseDto } from '../Base/BaseResponseDto';
import { CheckEmailDTO, CheckPhoneNumberDTO, CreateCustomerDTO } from 'app/@Services/models/register/register';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  ServiceUrl: string = environment.SiteBaseUrl + "Users/";
  constructor(private gService: GenericService) { }

  async checkEmail(emailObj: CheckEmailDTO): Promise<BaseResponseDto> {
    return await this.gService.getDataAsyc<BaseResponseDto>(this.ServiceUrl + "CheckEmail", emailObj);
  }

  async checkPhoneNumber(phoneNumObj: CheckPhoneNumberDTO): Promise<BaseResponseDto> {
    return await this.gService.getDataAsyc<BaseResponseDto>(this.ServiceUrl + "CheckPhoneNumber", phoneNumObj);
  }

  async createCustomer(createObj: CreateCustomerDTO): Promise<BaseResponseDto> {
    return await this.gService.getDataAsyc<BaseResponseDto>(this.ServiceUrl + "CreateNewCustomer", createObj);
  }

}
