import { Component, OnInit, Input } from '@angular/core';
import { ProductService } from '../../../@Services/services/product/product.service';
import { Observable } from 'rxjs';
import { BaseResponseDto } from '../../../@Services/services/Base/BaseResponseDto';
import { ProductDTO } from '../../../@Services/models/product/products-models';

@Component({
  selector: 'app-view-product-details',
  templateUrl: './view-product-details.component.html',
  styleUrls: ['./view-product-details.component.css']
})
export class ViewProductDetailsComponent implements OnInit {

  @Input() ProductDto: ProductDTO;
  constructor() { }

  ngOnInit(): void {

  }

}
