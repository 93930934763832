import { Injectable } from '@angular/core';
import { GenericService } from '../Base/GenericService';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { BaseResponseDto } from '../../services/Base/BaseResponseDto';
import { ProductsPagingReqestDTO, ProductByNumberDTO, CreateProductDTO, ProductsPagingReqestByFKDTO, ProductsByFKDTO } from '../../models/product/products-models';
import { PaginationRequestDTO } from 'app/@Services/models/generic-model/Pagination';


@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(public gService: GenericService) {
  }

  ServiceUrl: string = environment.SiteBaseUrl + "product/"

  getProductsPadging(request: ProductsPagingReqestByFKDTO): Observable<BaseResponseDto> {
    return this.gService.postData<BaseResponseDto>(this.ServiceUrl + "GetList", request);
  }

  getProductsDDL(request: ProductsByFKDTO): Observable<BaseResponseDto> {
    return this.gService.postData<BaseResponseDto>(this.ServiceUrl + "GetDDL", request);
  }

  getByNumber(requestDTO: ProductByNumberDTO): Promise<BaseResponseDto> {
    return this.gService.postDataAsyc<BaseResponseDto>(this.ServiceUrl + "GetByNumber", requestDTO);
  }

  creatNew(createObj: CreateProductDTO): Promise<BaseResponseDto> {
    return this.gService.postDataAsyc<BaseResponseDto>(this.ServiceUrl + "Create", createObj);
  }

  getProductsBySubCategory(request: ProductsPagingReqestByFKDTO): Promise<BaseResponseDto> {
    return this.gService.postDataAsyc<BaseResponseDto>(this.ServiceUrl + "GetListBySubCategory", request);
  }

  async getProductsPadgingAdmin(request: PaginationRequestDTO): Promise<BaseResponseDto> {
    return await this.gService.getDataAsyc<BaseResponseDto>(this.ServiceUrl + "GetListAdmin", request);
  }

  // getProductsByFK(request: ProductsPagingReqestByFKDTO): Promise<BaseResponseDto> {
  //   return this.gService.postDataAsyc<BaseResponseDto>(this.ServiceUrl + "GetListByFK", request);
  // }

  GetCatSubCatUsed(): Observable<BaseResponseDto> {
    return this.gService.GetData<BaseResponseDto>(this.ServiceUrl + "GetCatSubCatUsed");
  }

  FillMenu(): Observable<BaseResponseDto> {
    return this.gService.GetData<BaseResponseDto>(this.ServiceUrl + "FillMenu");
  }

}
