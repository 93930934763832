import { Injectable } from '@angular/core';
import { GenericService } from '../Base/GenericService';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { BaseResponseDto } from '../../services/Base/BaseResponseDto';
import { GetSubcategoriesByCategoryId } from 'app/@Services/models/subCatgory/subCategory-models';

@Injectable({
  providedIn: 'root'
})
export class SubCategoryService {

  ServiceUrl: string = environment.SiteBaseUrl + "subcategory/"

  constructor(private gService: GenericService) { }

  async getDropDownListAsync(request: GetSubcategoriesByCategoryId): Promise<BaseResponseDto> {
    return await this.gService.getDataAsyc(this.ServiceUrl + "GetDropDownList", request);
  }
  
  async getListAsyncByFK(request: GetSubcategoriesByCategoryId): Promise<BaseResponseDto> {
    return await this.gService.getDataAsyc(this.ServiceUrl + "GetSubCategoriesByFKey", request);
  }


}
