import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../../@Services/services/auth/auth.service';
import { CustomerDto } from '../../../@Services/models/auth/auth-model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(public translate: TranslateService, private modalService: NgbModal, private authService: AuthService) {

    this.translate.addLangs(['ar', 'en'])
    this.translate.setDefaultLang('en');
    const browserLang = translate.getBrowserLang();
    this.translate.use(browserLang.match(/ar|en/) ? browserLang : 'en');
  }

  showHideButtons: Boolean = false;
  customer: CustomerDto;

  ngOnInit(): void {
  }

  ChangeLanguage(language: string) {
    this.translate.use(language);
    this.showHideButtons = !this.showHideButtons;
  }

  openLoginModel(content: any) {
    this.modalService.open(content, { size: 'md' });
  }

  openRegistrationModel(content: any) {
    this.modalService.open(content, { size: 'md' });
  }

  LoggedIn() {
    //this.authService.LoggedIn();
    return this.customer = this.authService.whoLoggedIn();
  }

  Logout() {
    this.authService.logout();
  }

}
