import { Component, OnInit } from '@angular/core';
import { ProductsPagingReqestByFKDTO, ProductDTO } from 'app/@Services/models/product/products-models';
import { ProductService } from 'app/@Services/services/product/product.service';
import { BaseResponseDto } from 'app/@Services/services/Base/BaseResponseDto';
import { ActivatedRoute } from '@angular/router';
import { SiteMapDTO } from 'app/@Services/models/generic-model/siteMap';

@Component({
  selector: 'app-display-products',
  templateUrl: './display-products.component.html',
  styleUrls: ['./display-products.component.css']
})
export class DisplayProductsComponent implements OnInit {

  constructor(private productService: ProductService, private activatedRoute: ActivatedRoute) { }

  productList: ProductDTO[] = [];
  siteMapObj: SiteMapDTO;
  obj: ProductsPagingReqestByFKDTO;

  ngOnInit(): void {
    this.loadProducts(+this.activatedRoute.snapshot.paramMap.get('id'));
  }

  loadProducts(subcategoryId: number) {
    this.obj = { skip: 0, subcategoryId: subcategoryId, take: 100, name: '' }
    this.productService.getProductsBySubCategory(this.obj).then((result): BaseResponseDto => {
      this.siteMapObj = result.innerData as SiteMapDTO; //;
      this.productList = this.siteMapObj?.dataList as ProductDTO[];
      return;
    })
  }



}
