import { environment } from 'environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LoginDto, CustomerDto } from 'app/@Services/models/auth/auth-model';
import { GenericService } from '../Base/GenericService';
import { BaseResponseDto } from '../Base/BaseResponseDto';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  baseUrl = environment.SiteBaseUrl + 'Auth/';
  jwtHelper = new JwtHelperService();
  decodedToken: any;
  customer: CustomerDto;

  constructor(private genericService: GenericService, private httpClient: HttpClient) { }

  async login(model: LoginDto): Promise<BaseResponseDto> {
    return this.genericService.getDataAsyc<BaseResponseDto>(this.baseUrl + 'Login', model)
      .then((result): BaseResponseDto => {
        if (result.statusRequest) {
          let token: string = result.innerData as string
          localStorage.setItem('token', token);
          this.decodedToken = this.jwtHelper.decodeToken(token);
          console.log(this.decodedToken);
          return;
        }
      });

    // return this.httpClient.post(this.baseUrl + 'login', model).pipe(
    //   map((response: any) => {
    //     const user = response;
    //     if (user) {
    //       localStorage.setItem('token', user.token);
    //       this.decodedToken = this.jwtHelper.decodeToken(user.token);
    //       console.log(this.decodedToken);
    //     }
    //   })
    // );
  }

  async logout(): Promise<BaseResponseDto> {
    return this.genericService.getDataAsyc<BaseResponseDto>(this.baseUrl + 'Logout')
      .then((result): BaseResponseDto => {
        if (result.statusRequest) {
          localStorage.removeItem('token');
          return;
        }
      });
  }

  LoggedIn(): boolean {
    const token = localStorage.getItem('token');
    return !this.jwtHelper.isTokenExpired(token);
  }

  whoLoggedIn(): CustomerDto {
    const token = localStorage.getItem('token');
    if (!this.jwtHelper.isTokenExpired(token)) {
      this.customer = this.jwtHelper.decodeToken(token);
      console.log(this.customer);
      return this.customer;
    }
    else {

    }
  }

}
