import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { ViewProductsComponent } from './pages/view-products/view-products.component';
import { ViewSubCategoryComponent } from './components/view-subCategory/view-sub-category.component';
import { DisplayProductsComponent } from './components/display-products/display-products.component';
import { DisplayProductComponent } from './components/display-product/display-product.component';

const routes: Routes = [
  { path: 'Home', component: HomeComponent },
  { path: '', component: HomeComponent },
  { path: 'category/:id', component: ViewSubCategoryComponent },
  { path: 'subcategory/:id', component: DisplayProductsComponent },
  { path: 'product/:id', component: DisplayProductComponent },
  // admin
  { path: 'products', component: ViewProductsComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SiteRoutingModule { }
