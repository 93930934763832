import { Routes, RouterModule } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { NgModule } from '@angular/core';

export const AppRoutes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./site/site.module').then(mod => mod.SiteModule),
  },
  {
    path: 'admin',
    component: AdminLayoutComponent,
    loadChildren: () => 
    import('./layouts/admin-layout/admin-layout.module').then(mod => mod.AdminLayoutModule),
  },
  {
    path: '**',
    redirectTo: 'dashboard'
  }
]

@NgModule({
  imports: [RouterModule.forRoot(AppRoutes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
