// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  AuthBaseUrl: "",
  AdminBaseUrl: "",
  //SiteBaseUrl: "https://localhost:5001/api/",
  //SiteBaseUrl: "https://localhost:44362/api/",
  SiteBaseUrl: "http://shop-api.m7lk-online.com/api/",

  production: false
};
