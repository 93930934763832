import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class GenericService {

  constructor(private http: HttpClient) {
    
  }

  //#region Observable functions
  GetData<T>(actionUrl: string): Observable<T> {
    return this.http.get<T>(actionUrl);
  }

  // READ
  postData<T>(actionUrl: string, requestObj: T | any): Observable<T> {
    return this.http.post<T>(actionUrl, requestObj);
  }

  putData<T>(actionUrl: string, requestObj: T | any): Observable<T> {
    return this.http.put<T>(actionUrl, requestObj);
  }

  getData<T>(actionUrl: string, requestObj: T | any = {}): Observable<T> {
    return this.http.post<T>(actionUrl, requestObj);
  }

  DeleteData<T>(actionUrl: string): Observable<any> {
    return this.http.post<T>(actionUrl, {});
    // return this.http.delete<any>(actionUrl);
  }

  Deleteapi<T>(actionUrl: string, requestObj: T | any): Observable<any> {
    return this.http.delete<any>(actionUrl, requestObj);
  }

  getDataapi<T>(actionUrl: string, requestObj: T | any): Observable<any> {
    // const requestModel: RequestDto<T> = {
    //   // language: this.language.GetLanguageId(),
    //   requestData: requestObj,
    // };

    return this.http.get<any>(actionUrl, requestObj);
  }

  // CURD
  CreateUpdate<T>(actionUrl: string, requestObj: T | any): Observable<T> {
    // const requestModel: RequestDto<T> = {
    //   // language: this.language.GetLanguageId(),
    //   requestData: requestObj,
    // };
    return this.http.post<T>(actionUrl, requestObj);
  }

  update<T>(actionUrl: string, requestObj: T | any): Observable<T> {
    // const requestModel: RequestDto<T> = {
    //   // language: this.language.GetLanguageId(),
    //   requestData: requestObj,
    // };
    return this.http.put<T>(actionUrl, requestObj);
  }

  updateDataWithoutBaseModel<T>(actionUrl: string, requestObj: T | any): Observable<T> {
    return this.http.post<T>(actionUrl, requestObj);
  }

  //#endregion

  async postDataAsyc<T>(actionUrl: string, requestObj: T | any): Promise<T> {
    return await this.http.post<T>(actionUrl, requestObj).toPromise().then((value) => value);
  }

  async getDataAsyc<T>(actionUrl: string, model: any = {}): Promise<T> {
    return await this.http.post<T>(actionUrl, model).toPromise().then((value) => value);
  }

  // errorHandler(error) {
  //   let errorMessage = '';
  //   if (error.error instanceof ErrorEvent) {
  //     errorMessage = error.error.message;
  //   } else {
  //     errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
  //   }
  //   return; //throwError(errorMessage);
  // }

}
