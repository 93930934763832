import { Component, OnInit } from '@angular/core';
import { SubcategoryDTO, GetSubcategoriesByCategoryId } from '../../../@Services/models/subCatgory/subCategory-models';
import { SubCategoryService } from '../../../@Services/services/sub-category/sub-category.service';
import { BaseResponseDto } from '../../../@Services/services/Base/BaseResponseDto';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-view-sub-category',
  templateUrl: './view-sub-category.component.html',
  styleUrls: ['./view-sub-category.component.css']
})
export class ViewSubCategoryComponent implements OnInit {

  constructor(private subCategoryService: SubCategoryService, private activatedRoute: ActivatedRoute) { }
  subcategoryList: SubcategoryDTO[] = [];
  obj: GetSubcategoriesByCategoryId;

  ngOnInit(): void {
    this.viewSubCategories(+this.activatedRoute.snapshot.paramMap.get('id'));
  }

  viewSubCategories(categoryId: number) {
    this.obj = { categoryId: categoryId };
    this.subCategoryService.getListAsyncByFK(this.obj).then((result): BaseResponseDto => {
      this.subcategoryList = result.innerData as SubcategoryDTO[];
      return;
    });
  }

}
