import { Injectable } from '@angular/core';
import { GenericService } from '../Base/GenericService';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { BaseResponseDto } from '../../services/Base/BaseResponseDto';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  ServiceUrl: string = environment.SiteBaseUrl + "Language/"
  constructor(private genericService: GenericService) { }

  getDefualtLanguage(): Observable<BaseResponseDto> {
    return this.genericService.GetData<BaseResponseDto>(this.ServiceUrl + "GetDefault");
  }

}

