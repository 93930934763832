import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from '@angular/core';
import { ToastrModule } from "ngx-toastr";

import { SidebarModule } from '../app/layouts/sidebar/sidebar.module';
import { FooterModule } from '../app/layouts/shared/footer/footer.module';
import { NavbarModule} from '../app/layouts/shared/navbar/navbar.module';
import { FixedPluginModule} from '../app/layouts/shared/fixedplugin/fixedplugin.module';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { SiteModule } from './site/site.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BasicAuthInterceptorService } from './@Services/services/Interceptor/basic-auth-interceptor.service';
import { ErrorInterceptorService } from './@Services/services/Interceptor/error-interceptor.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LoadingInterceptor } from 'app/@Services/services/Interceptor/loading.interceptor';
// import { TranslateModule,TranslateLoader } from '@ngx-translate/core';
// import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// import { HttpClientModule,HttpClient } from '@angular/common/http';

// export function HttpLoaderFactory(http: HttpClient) {
//   return new TranslateHttpLoader(http);
// }

@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    // HttpClientModule,
    // TranslateModule.forRoot({
    //   loader:{
    //     provide: TranslateLoader,
    //     useFactory: HttpLoaderFactory,
    //     deps: [HttpClient]
    //   }
    // }),

    // RouterModule.forRoot(AppRoutes,{
    //   useHash: true
    // }),
    //AdminLayoutModule,
    AppRoutingModule,
    SidebarModule,
    NavbarModule,
    ToastrModule.forRoot(),
    FooterModule,
    FixedPluginModule,
    SiteModule,
    NgxSpinnerModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
