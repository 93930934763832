import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { UserService } from '../../../@Services/services/user/user.service';
import { BaseResponseDto } from '../../../@Services/services/Base/BaseResponseDto';
import { CheckEmailDTO, CheckPhoneNumberDTO, CreateCustomerDTO } from '../../../@Services/models/register/register';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  constructor(private fb: FormBuilder, private userService: UserService) { }

  frm: FormGroup;
  modelEMail: CheckEmailDTO = { email: '' };
  modelphoneNum: CheckPhoneNumberDTO = { phoneNumber: '' };
  createCustomer: CreateCustomerDTO = { email: '', fullName: '', password: '', phoneNumber: '', confirmPassword: '', cityId: 1, isCustomer: true, roleId: 3 };

  ngOnInit(): void {
    this.frm = this.fb.group({
      fullName: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(150)]],
      phoneNumber: [null, [Validators.required, Validators.minLength(11), Validators.maxLength(20), CheckExistsOrNot]],
      email: ['', [Validators.required, Validators.email, Validators.minLength(3), Validators.maxLength(60), CheckExistsOrNot]],
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(25)]],
    });
  }

  get EmailProperty() {
    return this.frm.get('email');
  }

  get PasswordProperty() {
    return this.frm.get('password');
  }

  get phoneNumberProperty() {
    return this.frm.get('phoneNumber');
  }

  get fullNameProperty() {
    return this.frm.get('fullName');
  }

  onSubmit() {
    if (this.frm.valid) {
      this.createCustomer.email = this.EmailProperty.value;
      this.createCustomer.fullName = this.fullNameProperty.value;
      this.createCustomer.phoneNumber = this.phoneNumberProperty.value;
      this.createCustomer.password = this.PasswordProperty.value;
      this.createCustomer.confirmPassword = this.PasswordProperty.value;

      this.userService.createCustomer(this.createCustomer).then((result): BaseResponseDto => {
        if (result.statusRequest) {
          if (result.innerData) {
            this.frm.reset();
          }
          return;
        }
      });
    }
  }

  isEmailExist() {
    const email = this.EmailProperty.value;
    if (email != null && email != '' && this.EmailProperty.touched) {
      this.modelEMail.email = email;
      this.userService.checkEmail(this.modelEMail).then((result): Promise<BaseResponseDto> => {
        if (result.statusRequest) {
          console.log(result.innerData);
          if (result.innerData) {
            this.EmailProperty.setValidators(CheckExistsOrNot(result.innerData));
            this.EmailProperty.updateValueAndValidity();
          }
          else {
            this.EmailProperty.clearValidators();
            this.EmailProperty.updateValueAndValidity();
          }
        }
        return;
      });
      return true;
    }
    return false;
  }

  isPhoneNumberExist() {
    const phoneNum = this.phoneNumberProperty.value;
    if (phoneNum != null && phoneNum != '' && this.phoneNumberProperty.touched) {
      this.modelphoneNum.phoneNumber = phoneNum;
      this.userService.checkPhoneNumber(this.modelphoneNum).then((result): Promise<BaseResponseDto> => {
        if (result.statusRequest) {
          if (result.innerData) {
            this.phoneNumberProperty.setValidators(CheckExistsOrNot(result.innerData));
            this.phoneNumberProperty.updateValueAndValidity();
          }
          else {
            this.phoneNumberProperty.clearValidators();
            this.phoneNumberProperty.updateValueAndValidity();
          }
        }
        return;
      });
      return true;
    }
    return false;
  }

}

// this is custom validation to check data is exists or not used for (email / phone number)
function CheckExistsOrNot(resultCheck: boolean) {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const email = control.value;
    if (email !== '') {
      if (resultCheck) {
        return { 'CheckExistsOrNot': true };
      }
      else {
        return null;
      }
    }
  };
}
